import React from 'react'
import { PageProps } from 'gatsby'
import { servicesBreadcrumbElement } from '.'
import { LayoutProps } from '../../components/Layout.context'
import { Helmet } from 'react-helmet'
import { StaticImage } from 'gatsby-plugin-image'
import { magasinLink } from '../../data/menu'
import ReparationExternalBrand from '../../components/ReparationExternalBrand'
import {ExternalBrandEnum} from "../../data/externalBrand";
import Advantages from "./Advantages";
import styled from "styled-components";

const brandName = ExternalBrandEnum.ORANGE.brandName
const fullTitle = 'Réparation de smartphones pour les clients ' + ExternalBrandEnum.ORANGE.brandName + ' et Sosh'
const colorBrand = ExternalBrandEnum.ORANGE.colorCode


const handleLinkClick = () => {
  // Set session storage data
  sessionStorage.setItem('fromPage', ExternalBrandEnum.ORANGE.code)
}
const circularIconStyle: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontFamily:'GothamRounded, sans-serif',
  width: '17px',
  height: '48px',
  color: '#FFFFFF',
  fontSize: '45px',
  fontWeight: '800',
  paddingTop: '6px',
};

const etapParagraph: React.CSSProperties = {
  fontSize: '18px',
  fontFamily: 'Arial, sans-serif',
  color: '#000000',
  textAlign: 'left',
  fontWeight: '400',
  lineHeight: '24px',
}

const AdvantagesTitle: React.CSSProperties = {
    fontSize: '22px',
    fontFamily: 'Arial, sans-serif',
    color: '#000000',
    textAlign: 'left',
    fontWeight: '700',
    lineHeight: '24px',
    padding: '26px',
}

const EtapTitle: React.CSSProperties = {
  fontSize: '22px',
  fontFamily: 'Arial, sans-serif',
  color: '#000000',
  textAlign: 'left',
  fontWeight: '700',
  lineHeight: '24px',
}

const AdvDiv = styled.div`
      background-color: #FFFFFF;
      border-radius: 8px;
      position: relative;
      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        padding: 20px;
        height: auto;
      }
    `

const ReparationOrange: React.FC<PageProps> = () => {
  return (
    <div>
      <Helmet>
        <meta
          name="description"
          title={fullTitle}
          content="Profitez de 20% de remise sur votre réparation de smartphone grâce au partenariat Save et Orange - Offre réservée aux clients Orange."
        />
      </Helmet>
      <ReparationExternalBrand>
        <ReparationExternalBrand.ReparationContainer>
          <ReparationExternalBrand.HalfGridContainer>
            <ReparationExternalBrand.DescriptionContainer mobileColor="#FFFFFF">
              <ReparationExternalBrand.H1Colored color={colorBrand}>
                Les avantages d’un partenariat <p>{brandName}</p> et <span style={{color: '#0F4C81'}}>Save</span>
              </ReparationExternalBrand.H1Colored>
              <ReparationExternalBrand.Paragraph style={etapParagraph}>
                Faites réparer partout en France votre téléphone acheté ou non chez Orange.
                Présentez-vous directement dans une boutique SAVE et bénéficiez d’un prix avantageux.
              </ReparationExternalBrand.Paragraph>
              <ReparationExternalBrand.Button
                to="/reparation"
                color={colorBrand}
                onClick={handleLinkClick}
              >
                Obtenir votre devis gratuit
              </ReparationExternalBrand.Button>
            </ReparationExternalBrand.DescriptionContainer>
            <ReparationExternalBrand.ImgContainer>
              <StaticImage
                src="../../../static/images/services/reparation_orange/SAVE-ORANGE.svg"
                alt="Orange and Save"
                placeholder="blurred"
                quality={100}
                width={234}
                height={237}
              />
            </ReparationExternalBrand.ImgContainer>
          </ReparationExternalBrand.HalfGridContainer>
        </ReparationExternalBrand.ReparationContainer>
        <AdvDiv>
          <ReparationExternalBrand.H2Colored style={AdvantagesTitle} color={colorBrand}>
            Les avantages pour les clients Orange et Sosh ?
          </ReparationExternalBrand.H2Colored>
          <Advantages/>
        </AdvDiv>

          <ReparationExternalBrand.BrandAdvantageContainer>
          <ReparationExternalBrand.H2Colored style={EtapTitle} color={colorBrand}>
            Les étapes de la réparation
          </ReparationExternalBrand.H2Colored>
          <ReparationExternalBrand.HalfGridContainer>
            <ReparationExternalBrand.AdvantagesContainer>
              <ReparationExternalBrand.AdvantageBodyContainer>
                <ReparationExternalBrand.AdvantageIconDiv>
                  <span style={circularIconStyle}>1</span>
                </ReparationExternalBrand.AdvantageIconDiv>

                <ReparationExternalBrand.Paragraph style={etapParagraph}>
                  Réalisez votre devis en ligne ou directement en boutique Save.
                  <pre>
                    <a href="/reparation" onClick={handleLinkClick}>
                      Obtenir votre devis gratuit
                    </a>
                  </pre>
                  </ReparationExternalBrand.Paragraph>
              </ReparationExternalBrand.AdvantageBodyContainer>
              <ReparationExternalBrand.AdvantageBodyContainer>
                <ReparationExternalBrand.AdvantageIconDiv>
                  <span style={circularIconStyle}>2</span>
                </ReparationExternalBrand.AdvantageIconDiv>

                <ReparationExternalBrand.Paragraph style={etapParagraph}>
                  Contactez par téléphone la boutique SAVE pour valider la disponibilité de la pièce.
                  <pre>
                  <a href={magasinLink}>Voir les boutiques Save</a>
                  </pre>
                  </ReparationExternalBrand.Paragraph>
              </ReparationExternalBrand.AdvantageBodyContainer>
              <ReparationExternalBrand.AdvantageBodyContainer>
                <ReparationExternalBrand.AdvantageIconDiv>
                  <span style={circularIconStyle}>3</span>
                </ReparationExternalBrand.AdvantageIconDiv>

                <ReparationExternalBrand.Paragraph style={etapParagraph}>
                  Allez en boutique SAVE avec une facture Orange ou Sosh à votre nom (dématérialisée ou papier) pour profiter de la remise.
                  <ReparationExternalBrand.SecondaryButtonWithColor color="#F80000">
                    Obligatoire
                  </ReparationExternalBrand.SecondaryButtonWithColor>
                  </ReparationExternalBrand.Paragraph>
              </ReparationExternalBrand.AdvantageBodyContainer>
              <ReparationExternalBrand.AdvantageBodyContainer>
                <ReparationExternalBrand.AdvantageIconDiv>
                  <span style={circularIconStyle}>4</span>
                </ReparationExternalBrand.AdvantageIconDiv>

                <ReparationExternalBrand.Paragraph style={etapParagraph}>
                  Réglez votre facture de réparation dans la boutique SAVE après les différentes remises accordées.
                  </ReparationExternalBrand.Paragraph>
              </ReparationExternalBrand.AdvantageBodyContainer>
            </ReparationExternalBrand.AdvantagesContainer>
            <ReparationExternalBrand.AdvantageAbsoluteSpan>
              <StaticImage
                src="../../../static/images/services/reparation_orange/carte-france.png"
                alt="Notre Expertise"
                placeholder="blurred"
                quality={100}
                width={399}
                height={350}
                imgClassName="img-expertise"
              />
              <ReparationExternalBrand.Paragraph>
                85% de la France couverte avec une boutique à -30km
              </ReparationExternalBrand.Paragraph>
            </ReparationExternalBrand.AdvantageAbsoluteSpan>
          </ReparationExternalBrand.HalfGridContainer>
        </ReparationExternalBrand.BrandAdvantageContainer>
        <ReparationExternalBrand.InformationContainer color="#ECEFF8">
          <ReparationExternalBrand.H2WithInformation>
            <span>i</span>
            <p>A savoir </p>
          </ReparationExternalBrand.H2WithInformation>
          <ReparationExternalBrand.Paragraph>
            Pour faire valoir votre garantie réparation de 12 mois, rendez-vous dans la boutique SAVE de votre choix avec votre facture de réparation.
          </ReparationExternalBrand.Paragraph>

        </ReparationExternalBrand.InformationContainer>
      </ReparationExternalBrand>
    </div>
  )
}

const layoutProps: LayoutProps = {
  title: fullTitle,
  breadcrumb: [servicesBreadcrumbElement, { label: fullTitle }],
}

export default Object.assign(ReparationOrange, {
  layoutProps,
})
